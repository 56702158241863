const state = {
    user: window.localStorage.getItem('user') || '{}',
    jwt: window.localStorage.getItem('jwt') || '',
    venue: window.localStorage.getItem('venue') || '{}',
    period: window.localStorage.getItem('period') || ''
}
const getters = {
    isAuthenticated: state => {
        return !!state.jwt
    },
    venueChosen: state => {
        const venue = JSON.parse(state.venue)
        return !!state.venue && venue.id != null
    },
    getUser: state => {
        return JSON.parse(state.user)
    },
    getJwt: state => {
        return state.jwt
    },
    getVenue: state => {
        return JSON.parse(state.venue)
    },
    getPeriod: state => {
        return state.period
    },
}
const actions = {
    logout: ({commit}) => {
        return new Promise( () => { //  (resolve, reject) => {
            commit('logout')
            localStorage.removeItem('user')
            localStorage.removeItem('jwt')
            localStorage.removeItem('venue')
            localStorage.removeItem('period')
          })
    }
}
const mutations = {
    
    updateUserData: (state, user) => {
        if (user.searchHistory == null) {
            user.searchHistory = []
        }
        localStorage.setItem('user', JSON.stringify(user))
        state.user = JSON.stringify(user)
    },
    updateJwt: (state, jwt) => {
        localStorage.setItem('jwt', jwt)
        state.jwt = jwt
    },
    updateVenue: (state, venue) => {
        localStorage.setItem('venue', JSON.stringify(venue))
        state.venue = JSON.stringify(venue)
    },
    updatePeriod: (state, period) => {
        localStorage.setItem('period', period)
        state.period = period
    },
    logout: (state) => {
        state.jwt = ''
        state.period = ''
        state.user = '{}'
        state.venue = '{}'
        localStorage.clear()
    }
}
export default {
    state,
    getters,
    actions,
    mutations
}