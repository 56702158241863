<template>
  <div class="searchContainer"> 
    <b-form-input ref="searchinput" list="my-list-id" v-model="search" v-on:keyup.enter="itemClickedFunction(search)" @keydown.tab="itemClickedFunction(search)" :placeholder="placeholder ? placeholder : $t('winelist.searchfieldPlaceholder')" autocomplete="off" @click.stop="searchInputHasFocus=true" @focus="arrowCounter=-1" @keyup="searchInputHasFocus=true" @keydown.down="onArrowDown" @keydown.up="onArrowUp" :disabled="disabled">
    </b-form-input> 
    <ul v-if="(searchAutosuggestHasFocus || searchInputHasFocus && ( !isLastSearch || autoSuggestOptions.length > 0 ))" class="autocomplete-container">
      <li v-if="isLastSearch"><b>{{$t('winelist.autocompleteLastSearches')}}:</b></li> 
      <li tabindex="0" v-on:blur="searchAutosuggestHasFocus=false" v-for="(item, indexauto) in autoSuggestOptions" :key="indexauto" ref="autocompletelist" :class="{ 'is-active': indexauto === arrowCounter }" v-on:click="setSearchItem(item)"  @keydown.down="onArrowDown" @keydown.up="onArrowUp" v-on:keyup.enter="setSearchAndLoadWines">
        {{ item.text }}
      </li>
      <li v-if="autoSuggestOptions.length === 0 && !isLastSearch" class="noHit">{{showNoHitText()}}</li>
    </ul>
    <span v-if="search != null && search.length > 0 && (isLastSearch || supplier)" @click="deleteSearch">
        <svg width="13" height="13" viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.728 2.243a6.002 6.002 0 0 1 0 8.485 6.002 6.002 0 0 1-8.485 0 6.002 6.002 0 0 1 0-8.485 6.002 6.002 0 0 1 8.485 0zM9.116 4.788l-.848-.848L6.57 5.637 4.874 3.94l-.849.848 1.697 1.697-1.697 1.697.849.849L6.57 7.334 8.268 9.03l.848-.849L7.42 6.485l1.697-1.697z" fill="#CED4DA" fill-rule="nonzero"/>
        </svg>
    </span>
  </div>
</template>

<script>
 import { mapGetters } from 'vuex'
  export default {
    name: 'SearchDataList',
    props: {
      searchFromOutside: String,
      autoSuggestComplete: Array,
      itemClickedFunction: Function,
      isLastSearch: Boolean,
      placeholder: String,
      noHitText: String,
      supplier: Boolean,
      disabled: Boolean
    },
    created () {
      if (this.searchFromOutside != null) {
        this.search = this.searchFromOutside
      }
    },
    data() {
        return {
          searchAutosuggestHasFocus: false,
          searchInputHasFocus: false,
          search: ''
        }
    }, 
    methods: {
        showNoHitText() {
          return this.noHitText ? this.noHitText : 'Kein Treffer für diese Eingabe'
        },
        onArrowDown() {
            if (this.arrowCounter < this.autoSuggestOptions.length - 1 && this.autoSuggestOptions.length > 0) {
              this.arrowCounter = this.arrowCounter + 1;
              this.$refs.autocompletelist[this.arrowCounter].focus()
              this.searchAutosuggestHasFocus = true
              this.searchInputHasFocus = false
            }
        },
        onArrowUp() {
            if (this.arrowCounter > 0) {
              this.arrowCounter = this.arrowCounter - 1;
              this.$refs.autocompletelist[this.arrowCounter].focus()
              this.searchAutosuggestHasFocus = true
              this.searchInputHasFocus = false
            } else {
              this.$refs.searchinput.focus()
              this.arrowCounter = -1;
              this.searchAutosuggestHasFocus = false
              this.searchInputHasFocus = true
            }
        },
        setSearchAndLoadWines (e) {
          if (e.keyCode === 13) {
            if (this.arrowCounter >= 0) {
              this.search = this.autoSuggestOptions[this.arrowCounter].text
              this.showAutoSuggest = false
              this.itemClickedFunction(this.search)
            }
          }
        },
        setSearchItem (item) {
          this.search = item.text
          this.searchAutosuggestHasFocus = true
          this.searchInputHasFocus = false
          this.itemClickedFunction(item)
          this.$store.commit('clickedOutside')
        },
        deleteSearch() {
          if (this.supplier) {
            this.itemClickedFunction({ text: '', value: null})
          } else {
            this.itemClickedFunction({ text: '', value: '' })
          }
          this.search = ""
          this.$store.commit('clickedOutside')
        },
        leaveInput() {
          this.$nextTick(() => {
            this.searchInputHasFocus = false
            this.searchAutosuggestHasFocus = false
          })
          /*
          if(!this.isLastSearch) {
            this.itemClickedFunction(this.search)
          }
          */
        }
    },
    computed: {
      ...mapGetters(['getClickedOutside']),
      autoSuggestOptions () {
        if (this.search != null && this.search.length > 0) {
          var returnArray = this.autoSuggestComplete.filter(search => search.text.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
          return returnArray.slice(0,20)
        } else return this.autoSuggestComplete
      },
    },
    watch: {    
      getClickedOutside() {
        this.searchInputHasFocus = false
        this.searchAutosuggestHasFocus = false
      },
      searchFromOutside: {
        immediate: true,
        deep: true,
        handler() {
          this.search = this.searchFromOutside
        }
      }
    }
  }
</script>
<style scoped>
</style>

