const state = {
    showPlaylistPopup: false,
    reloadWineSuppliers: false,
    reloadWines: false,
    reloadVenue: false,
    reloadReports: false,
    clickedOutside: false,
    alert: {
        alertText: '',
        alertVariant: 'info'
    }
}
const getters = {
    getShowPlaylistPopup: state => state.showPlaylistPopup,
    
    getReloadWineSuppliers: state => state.reloadWineSuppliers,

    getReloadWines: state => state.reloadWines,

    getReloadReports: state => state.reloadReports,

    getReloadVenue: state => state.reloadVenue,

    getClickedOutside: state => state.clickedOutside,

    getAlert: state => state.alert,

}
const actions = {
}
const mutations = {
    showPlaylistPopupToggle: (state) => {
        state.showPlaylistPopup = !state.showPlaylistPopup
    },
    reloadWineSuppliers: (state) => {
        state.reloadWineSuppliers = !state.reloadWineSuppliers
    },
    reloadWines: (state) => {
        state.reloadWines = !state.reloadWines
    },
    reloadReports: (state) => {
        state.reloadReports = !state.reloadReports
    },
    reloadVenue: (state) => {
        state.reloadVenue = !state.reloadVenue
    },
    clickedOutside: (state) => {
      state.clickedOutside = !state.clickedOutside
    },
    setAlertText: (state, {text, variant}) => {
        state.alert.alertText = text
        state.alert.alertVariant = variant
    },
}
export default {
    state,
    getters,
    actions,
    mutations
}